import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload,
      oldPath: null,
      newPath: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    "$route.path": {
      handler(newPath, oldPath) {},
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },
  async mounted() {}
};